<template>
  <div class="relative">
    <button
      class="flex items-center p-0-5 pr-1 rounded-md"
      :class="{ 'bg-gray-200': showMenu }"
      ref="menuActivator"
      v-on-click-outside="closeMenu"
      @click="toggleMenu"
    >
      <icon-base
        height="20"
        width="20"
        viewBox="0 0 20 14"
        icon="show-pass-no-bg"
        class="ml-1 text-gray-500"
      />
      <span>{{ seenByCount }}</span>
    </button>
    <teleport to="#menus">
      <div
        v-if="showMenu"
        ref="menuRef"
        class="absolute z-[102] shadow-xl bg-white w-64 py-4 pl-3 pr-5 z-100 overflow-y-hidden"
        :style="`top: ${top - getOffset()}px; left: ${left - 200}px`"
      >
        <lf-h3>{{ $t("DEALS.DEAL_NOTES.VIEWS") }}</lf-h3>
        <div class="mt-2">
          {{ $t("DEALS.DEAL_NOTES.USERS_WHO_SAW_NOTE") }}
        </div>
        <div class="mt-4 max-h-50 overflow-y-auto">
          <div
            v-for="record in seenBy"
            :key="record.id"
            class="mb-3 flex items-center space-x-2"
          >
            <lf-avatar :username="formatUserFullName(record.user)" size="40" />
            <div>
              <div class="font-semibold text-gray-600">
                {{ record.user.first_name }} {{ record.user.last_name }}
              </div>
              <div>{{ record.user.email }}</div>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import { computed, ref } from "vue";
import type { IReadNoteRecord } from "@/models/applications";
import { useDropdownMenu } from "@/hooks/menus";
import { formatUserFullName } from "@/helpers/formatting";

const props = defineProps({
  seenBy: {
    type: Array as PropType<IReadNoteRecord[]>,
    required: true
  }
});

const { showMenu, toggleMenu, closeMenu, top, left, menuActivator } =
  useDropdownMenu("notes-list");

const menuRef = ref<HTMLElement | null>(null);

const getOffset = () => {
  const padding = 30;
  const noteListBottom = document
    .getElementById("notes-list")
    ?.getBoundingClientRect().bottom;
  const menu = menuRef.value?.getBoundingClientRect();

  if (noteListBottom && menu?.bottom && menu.bottom > noteListBottom) {
    return menu.height + padding;
  }
  return 0;
};
getOffset();
const seenByCount = computed(() => props.seenBy?.length);
</script>
